.base-container {
  background-color: var(--primary-color);
  color: var(--white);
  font-family: 'Marcellus', serif;
  margin-left: 2vw;
}

.base-logo {
  width: 100px;
  height: auto;
  margin-bottom: 10px;
}

.base-title {
  text-align: left;
  font-family: 'Marcellus', serif;
}

.base-subtitle {
  margin-bottom: 20px;
  text-align: left;
  font-family: 'Marcellus', serif;
}

.base-contact, .base-visit {
  margin-top: 10px;
  margin-bottom: 5px;
}

.base-email a, .base-social a {
  color: white;
  text-decoration: none;
  align-self: center;
}

.base-social {
  display: flex;
  width: max-content;
  justify-content: center;
}

.base-socials {
  display: flex;
  width: max-content;
  justify-content: center;
  align-self: center;
}

.base-social a {
  margin-left: 5px;
}

.base-left {
  display: flex;
  width: max-content;
}

.base-right {
  text-align: left;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.base-direct-links {
  font-weight: bold;
}

.base-link {
  margin-bottom: 3vh;
  color: var(--white);
}

.base-button {
  margin-top: 20px;
  color: var(--white);
  border-color: white;
}

.base-bottom {
  text-align: center;
  margin-top: 40px;
  font-size: 12px;
}

.base-bottom > a{
  color: white;
  text-decoration: none;
}