@keyframes mainBGcolor {
    from {background-color:rgba(0, 0, 0, 0);}
    to {top: var(--primary-color);}
}

.header {
    height: 90px;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 3;
    transition: .2s ease-in;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0);
}

.hidden {
    display: none;
}

:root {
  --scroll-value: 1060;
}

.fixed {
    position: fixed;
    background-color: var(--primary-color);
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100vw;
    margin: auto;
    height: 100%;
    padding: 0 5vw;
}

.header .nav-menu a {
    color: #eeeeee;
    font-family: "Marcellus", serif;
    font-style: normal;
    font-size: var(--font-size-h3);
}



.header .nav-item {
    position: relative;
    left: 20%;
    margin-top: 20%;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.headertitle {
  width: 72px;
  height: auto;
}

.logo {
  z-index: 2;
  top: 0;
  left:  calc(50% - 36px);
  width:  72px;
  height: auto;
  align-self: center;
}

.hamburger {
    display: block;
}

.shoppinCartNavbar {
    position: relative;
    left: 0;
    color: #000000;
}

.shoppinCartNavbarAbsolute {
    position: absolute;
    left: 41vw;
    color: #000000;
}

.nav-menu {
    position: fixed;
    left: 0;
    top: 0;
    flex-direction: column;
    background-color: rgba(43, 43, 43, 0.95);
    width: 40%;
    height: 100%;
    z-index: 3;
    text-align: center;
    display: none;
    animation: slideInOut 1s ease-in-out;
}

.active {
    display: flex;
}

ul.menu-items {
    position: absolute;
    top:20%
}

@media screen and (max-width:940px) {
    .header {
        max-width: 100vw;
        background-color: rgba(f,f,f,.9);
    }

    .header .navbar {
        max-width: 100vw;
    }

    .hamburger {
        display: block;
    }

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(f,f,f,.9);
        width: 100vw;
        height: 90vh;
        z-index: 3;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem 0;
    }


    .shoppinCartNavbar {
        position: relative;
        left: 0;
        color: #000000;
    }

    .shoppinCartNavbarAbsolute {
        position: relative;
        left: 0;
        color: #000000;
    }
}

@media screen and (max-height: 2000px) {
    :root {
      --scroll-value: 375;
    }
}

@media screen and (max-width: 800px) and (max-height: 1100px) {
  :root {
    --scroll-value: 360;
  }
}

@media screen and (max-width: 500px) and (max-height: 900px) {
  :root {
    --scroll-value: 225;
  }
}

@media screen and (max-width: 500px) and (max-height: 700px) {
  :root {
    --scroll-value: 160;
  }
}

@media screen and (max-width: 500px) and (min-height: 900px) {
  :root {
    --scroll-value: 300;
  }
}

@media screen and (max-width: 420px) and (min-height: 900px) {
  :root {
    --scroll-value: 320;
  }
}















