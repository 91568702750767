@keyframes letrasKeyFrame {
    from {top:0;}
    to {top: 50%;}
}

.philosophy-section {
  background-color: #5c5c5c;
  color: white;
  height: 100%;
  padding: 0 50px;
  z-index: 2;
}

.philosophy-image-container {
  position: absolute;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100%;
  width: auto;
  margin-top: 5%;
}

.philosophy-image {
  width: auto;
  height: 100%;
  border-radius: 10%;
  opacity: 0.5;
  transition: opacity 0.3s ease; /* Añadir una transición suave */
}

.philosophy-image:hover {
  opacity: 0.7; /* La imagen se vuelve completamente opaca al hacer hover */
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

:root {
    --scroll:0;
}

.letras-container {
    z-index: 2;
    align-self: flex-start;
    position: relative;
    width: 50%;
    top:0;
    animation-name: letrasKeyFrame;
    animation-duration: 5s;
    animation-play-state: paused;
    animation-delay: calc((var(--scroll) - 0.1) * -10s);
}

.philosophy-content {
  max-width: 1200px;
  text-align: left;
  z-index: 2;
}

.philosophy-title {
  color: #7a5f18; /* Dorado */
  margin-bottom: 20px;
  z-index: 2;
  font-family: "Marcellus", serif;
}

.philosophy-description {
  font-family: "Marcellus", serif;
  font-style: normal;
  font-size: 30px;
  color: white;
  max-width: 100%;
  line-height: 1.6;
  z-index: 2;
}


@media (max-height: 1000px) {

    .letras-container {
        animation-duration: 15s;
    }
}
@media (max-height: 900px) {
  .philosophy-title {
    font-size: 3rem;
  }

.philosophy-image {
  width: auto;
  height: 100%;
  border-radius: 10%;
  opacity: 0.3;
  transition: opacity 0.3s ease; /* Añadir una transición suave */
}

.philosophy-image:hover {
  opacity: 0.5; /* La imagen se vuelve completamente opaca al hacer hover */
}

.letras-container {
    animation-duration: 15s;
}

}
