@keyframes backgroundWineKeyFrame {
    from {background-color:#000;}
    to {background-color:#5c5c5c;}
}

.black-background {
    background-color: #000;
    animation-name: backgroundWineKeyFrame;
    animation-duration: 6s;
    animation-play-state: paused;
    animation-delay: calc((var(--scroll) - 0.2) * -7s);

}

:root {
    --scroll:0;
}

.wineTextSection {
    color: #E7E3E1;
    font-family: "Marcellus", serif;
    margin: 3%;
    max-width: inherit;
}
.bottleVideo {
    z-index: 4;
    position: fixed;
    height: auto;
    width: 100vw;
    background-color: #000;
}


.bottle-little-video {
    position: relative;
    height: 70vh;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}