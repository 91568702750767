@keyframes bottleGradientKeyFrame {
    0% {
        background-color: #5c5c5c; /* Degradado sólido */
    }
    60% {
        background-color: maroon;
    }
    100% {
        background-color: #5c5c5c;
    }
}

.general {
  background: #5c5c5c;
  animation-name: bottleGradientKeyFrame;
  animation-duration: 2s;
  animation-play-state: paused;
  animation-delay: calc((var(--scroll) - 0.6) * -6.667s);
}

.logo-container {
  position: sticky;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo-container .logo-animator {
  position: absolute;
  z-index: 2; /* Asegura que el logo esté por encima */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
}

.logo-container .video-animator {
  width: 100%;
  height: 100vh;
  z-index: 1; /* Asegura que el video esté detrás */
}

video {
  width: 100%;
  height: 100vh;
  object-fit: cover; /* Cubre todo el contenedor sin distorsionarse */
  position: absolute;
  top: 0;
  left: 0;
}

.video-fixed {
  position: fixed;
  top: 0; /* Asegura que el video esté en la parte superior de la ventana */
  left: 0;
  width: 100%;
  z-index: 1; /* Ajusta el z-index para que no interfiera con otros elementos */
  display: block;
  height: 100%;
}

:root {
  --video-scroll-value: 1060;
}

.video-inactive {
  display: none;
}

.video-sticky {
    position: sticky;
    top: 0; /* Asegura que el video esté en la parte superior de la ventana */
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
}

.lawyer {
  position: relative;
  z-index: 2; /* Contenido Lawyer delante del video */
}

.riberaMapImage {
    margin: auto;
    width: 80vw;
    align-self: center;
}

.riberaMapImageContainer {
    max-width: 100vw;
    display: flex;
}

.bottle {
  /* Tus estilos aquí */
  z-index: 2; /* Asegura que esté delante del video */
}

#conocenosButton {
    font-size: 2vw;
    min-width: 20vw;
    left: 40vw;
    margin-top: 10vh;
    font-family: "Marcellus", serif;
    color: #eeeeee;
    align-self: center;
}