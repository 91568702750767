

.footer {
    width: 100%;
    background-color: var(--primary-color);
    color: var(--white);
    padding: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
}

.footerTitle {
    text-align: center;
}

.footerIMG {
    width: 100%;
    height: auto;
    margin: 0;
}

.footer .container {
    max-width: 1240px;
    height: 100px;
    margin: auto;
    text-align: center;
    z-index: 3;
}

.footer ul {
    display: flex;
    justify-content: center;
}

.footer li {
    padding: 1rem;
}

.footer li a {
    color: #fff;
}

.footer li a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.bottom {
    text-align: center;
    padding-top: 3rem;
}

.line {
    margin-bottom: 1rem;
}





