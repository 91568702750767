.title {
    order: 0;
    flex: 0 1 auto;
    align-self: center;
    z-index: 3;
    width: 72px;
    height: auto;
}

@media screen and (max-width:2600px) {
    .container_title {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
    }

}

@media screen and (max-width:1300px) {
    .container_title {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
    }
}
@media screen and (max-width:940px) {
    .hero .content p:nth-child(3) {
        font-size: 2.6rem;
    }

    .hero .content p:nth-child(4) {
        font-size: 1.6rem;
    }
}


@media screen and (max-width:400px) {
    .container_title {
        height: 70%;
        width: 100%;
    }
}