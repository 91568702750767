@keyframes arrowKeyFrame {
    from {top:55%;}
    to {top: 100%;}
}

.container_arrow {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
}

:root {
    --scroll:0;
}

.arrow {
    height: 150px;
    position: absolute;
    flex: 0 1 auto;
    align-self: auto;
    z-index: 2;
    animation-name: arrowKeyFrame;
    animation-duration: 5s;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -25s);
    top: 55%;
}