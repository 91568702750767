.picture {
    width: 25%;
    height: auto;
    margin: 0 10px;
    align-self: center;
}

.pictures {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}