.backgroundForm {
    background-color: #eee;
}

.formClass {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 100px auto;
    color: #5c5c5c;
}

#redsysButton [type="submit"] {
  background-color: var(--maroon);
  border: none;
  color: #eee;
  padding: 7px 0;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  width: 120px;
}

.textField {
    margin: 15px;
}