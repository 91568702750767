:root {
  --primary-color: #5c5c5c;
  --maroon: #7c1d12;
  --white: #eeeeee;
  --title-effect-value: -1390px;
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.button {
  padding: 17px 28px;
  font-size: 1.2rem;
  color: #fff;
  text-transform: uppercase;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background: var(--primary-dark);
  border: 1px solid var(--primary-dark);
}

.line {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px auto;
  background-color: var(--primary-color);
}

:root {
  --font-size-h1: 5.5rem;  /* 88px */
  --font-size-h2: 4rem;    /* 64px */
  --font-size-h3: 3rem;    /* 48px */
  --font-size-h4: 2rem;    /* 32px */
  --font-size-h5: 1.5rem;  /* 24px */
  --font-size-h6: 1.25rem; /* 20px */
}

h1 {
  font-size: var(--font-size-h1);
  font-weight: normal;
}

h2 {
  font-size: var(--font-size-h2);
  font-weight: normal;
}

h3 {
  font-size: var(--font-size-h3);
  font-weight: normal;
}

h4 {
  font-size: var(--font-size-h4);
  font-weight: normal;
}

h5 {
  font-size: var(--font-size-h5);
  font-weight: normal;
}

h6 {
  font-size: var(--font-size-h6);
  font-weight: normal;
}



@media screen and (max-width:1281px) {
    :root {
      --title-effect-value: -1390px;
    }
}

@media screen and (max-width: 1100px) {
    :root {
      --title-effect-value: -1045px;
    }
}

@media screen and (max-width:800px) {
    :root {
      --title-effect-value: -750px;
      --font-size-h1: 3rem;  /* 88px */
      --font-size-h2: 2rem;    /* 64px */
      --font-size-h3: 2rem;    /* 48px */
    }
}

@media screen and (max-width:400px) {
    :root {
      --title-effect-value: -345px;
    }
}

@media screen and (max-width: 500px) and (min-height: 900px) {
  :root {
    --title-effect-value: -330px;
  }
}
