.privacy-policy-container {
  background-color: #5c5c5c;
  color: #fff;
  padding: 20px;
  min-height: 100%;
}

.privacy-policy-title {
  margin-bottom: 10vh;
}

.privacy-policy-subtitle {
  color: #7c1d12;
  text-align: center;
  margin-bottom: 20px;
}

.privacy-policy-paragraph {
  color: #eeeeee;
  margin-bottom: 2vh;
  margin-left: 30vh;
  margin-right: 30vh;
}

.privacyPolicy ul {
  color: #eeeeee;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 5vh;
  margin-left: 32vh;
  margin-right: 30vh;
}

.privacy-policy-paragraph:last-of-type {
  padding-bottom: 10vh;
  margin-bottom: 0;
}
